import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShoppingCartProvider } from "./components/_default/context/ShoppingCartContext"
import store from "./store/store"
import Redirect from "./components/_default/utilities/Redirect"
import TagManager from 'react-gtm-module'
// Pages
import Webpage2Avaleht from "./pages/Webpage2Avaleht";
import Webpage12Lehte_ei_leitud from "./pages/Webpage12Lehte_ei_leitud";
import Webpage14Tingimused from "./pages/Webpage14Tingimused";

var baseURL = 'https://web.kassahaldus.ee/webstoreApi';
var axiosHeaders = {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiYThiMDUwNDQ3OTlkYzJlYjBmNGEzNWU1ZjU0NGM5ZDgwYTNhODgwZTM0NDYzYjIwMTg3YTRhOGI5MWNjMmEwMjMyMGE0NzVmZTlhODNjZTYiLCJpYXQiOjE3MjI2ODU2OTQuMzA1NTMsIm5iZiI6MTcyMjY4NTY5NC4zMDU1MzcsImV4cCI6MTcyMjc3MjA5NC4zMDE2Nywic3ViIjoiOCIsInNjb3BlcyI6W119.U4isREqxSLX9Y0JLaq3FacWlQSj5ZUs3mwXKYoyIe8tnGki5ufCVA7g06eWaYrKBfxjw8uplPcoAPwqpXWTxSDCu8BycK3L9vh8FADuBAT9Vf_D9rdb_lOPkzQUUXOXsTTvNdJKk2DoktFQXacHSluBcvcxf0K3QQ80lnc-DKO9V-U7fnE0k8syBaG_U7bnWz0D2-dIYT_4S7NeBaHMsROBt_QYB1aOlSPivFiADpN9LntQeS9LQpM30mdoCD8tlN0d4OsPL-dEq3y0TX4RDHLAf9JIQpuP7oNE5xmtCpM75q7OvuoCUHrtK0QDTPOLpJk7Ph3Pjg-sh7_l2js04eBgWU3CduebSibr4gxej5yVQyjtzGSWEI0yGk1FjzJ7zD7jFKxxpnH9AwtdNEAt_MT9Jyagy8yHtdNlMfaWim51LIR-l23XeKqR-DLRmh_hCDMH7nVYYLYJr-24BKkYd7DDfrWV3ikJIWQcnqtXOgFyO7U5Fz0bqFV9kg-cKU36TYGN77oVw3F4oyzSz-4ZH6D7es2tGSp7GnXlH9cp7XvWxJYDmRidGxf6bH3KGzMLkR74ksw_obO78pWFpiWsKlkB5lUUwNkQCYcWySRTPvACklMrdQ3-6hFlVC7LgBapFDYtnA1YmUlbWQyu2hMy8G65J3x9WSe2N_IhDBoW_xXw',
    'Access-api-key': "eyJpdiI6ImF1OEozTENmNzh0dHAzMTZvcGJyMFE9PSIsInZhbHVlIjoiejlkWTRzbHVmYS9JUVJDTkg1d3RuZz09IiwibWFjIjoiYTgwMDI1NjZlNTNlOGI0Y2FkNTcwZmY1YzQwZTY1MzRmZjc4ZmM0YWQxNDlkZjViYzQxOWEzYjk1YmRkMTQyMyIsInRhZyI6IiJ9",
    'Access-api-id': "eyJpdiI6Ilhyd0dVZ1VqT3ZZQXAzakYwUDQzcnc9PSIsInZhbHVlIjoieWYyQnhKNkZJMnNhOFpvTGxHRjh5QT09IiwibWFjIjoiNzQyYzcwMWM1NzQ3YmJiMGY0MzE1MmM2MWU5MTQ4M2M3ZDgxNWRmYzVjYmVlNjg0ZmQwMTE5ZTIwOTdmZTJkYiIsInRhZyI6IiJ9",
    'Access-Control-Allow-Origin': "*"
 };


window.axios = require('axios').create({
  baseURL: baseURL,
  headers: axiosHeaders
});
const tagManagerArgs = {
    gtmId: "GTM-KHPWXL9"
}
TagManager.initialize(tagManagerArgs)
    
export default function App() {
    window.dataLayer.push({
        event: 'pageview'
    });
    return (
        <Provider store={store}>
            <ShoppingCartProvider>
                <BrowserRouter basename="/">
                    <Routes>
                                            <Route path="/et/" element={<Webpage2Avaleht />} exact />;
                                                                    <Route path="/et/*" element={<Webpage12Lehte_ei_leitud />} exact />;
                                                    <Route path="*" element={<Webpage12Lehte_ei_leitud />} exact />;
                                                                    <Route path="/et/tingimused" element={<Webpage14Tingimused />} exact />;
                                                                                    </Routes>
                </BrowserRouter>
            </ShoppingCartProvider>
        </Provider>
    );
}