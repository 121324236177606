import { Console } from "console";
import { useAppSelector } from "../../../store/hooks";
import { X } from "@mui/icons-material";

export function pf(number: number) {
  var decimals = 2;
  var pow = Math.pow(10, decimals);
  return Number(Math.round(number * pow) / pow).toFixed(decimals);
}

// Same function in app/Webpage.php if changes then change in here too
export function getPageLocaleUrl(page: any, languages: any) {
  // @ts-ignore

  let locale = "et";
  let language = page.language;
  if (language && Array.isArray(languages)) {
    // @ts-ignore

    if (page.language.language_id && languages.length > 0) {
      const updatedLocale = languages.filter(
        (x) => x.language?.id === page.language?.language_id
      )[0]?.language.shortname;
      locale = updatedLocale;
    }
  }
  return "/" + locale + "/" + page.url;
}

type LogErrorProps = {
  name: string | any;
  message: string | any;
  stack: string | any;
  componentStack: string | any;
  href: string | any;
};
var logStack: any = [];
var logStackTimeout: any;
export function logErrorToBackend(data: LogErrorProps) {
  logStack.push(data);
  if (logStackTimeout) {
    clearTimeout(logStackTimeout);
  }
  logStackTimeout = setTimeout(() => {
    logErrorStackToBackend();
  }, 3000);
}
function logErrorStackToBackend() {
  if (logStack.length) {
    const data: any = {
      logStack: logStack,
    };
    logStack = [];
    // @ts-ignore
    window.axios
      .post("logError", data)
      .then((res: any) => {})
      .catch((error: any) => {
        console.log(error);
      });
  }
}
