import { useAppSelector } from "../../store/hooks";

import { useLocation } from "react-router-dom";
import { logErrorToBackend } from "./utilities/helpers";
import ContentRenderWebpageComponent from "./ContentRenderWebpageComponent";
import "./Default.css";
import "./ShopTheme.css";
import { useEffect } from "react";
import CookiePolicyComponent from "./CookiePolicyComponent";
import ShoppingCartExpired from "./ShoppingCart/ShoppingCartExpired";
import React from "react";

declare var defaultComponents: any;

export default function ContentRenderer() {
  const fontUrl = useAppSelector((state) => state.builder.font_url.url);
  const webpageComponents = useAppSelector(
    (state) => state.builder.structure.webpageComponents
  );
  const website = useAppSelector((state) => state.builder.website);
  const colors = useAppSelector((state) => state.builder.colors);
  const isBuilder = document.getElementById("BuilderContent");

  useEffect(() => {
    if (colors) {
      const shopThemeColor = colors.find((x) => x.name === "SHOPTHEMECOLOR");

      if (shopThemeColor?.color) {
        document.documentElement.style.setProperty(
          "--shop-theme-color",
          shopThemeColor?.color
        );
      }
    }
  }, [colors]);

  useEffect(() => {
    if (colors) {
      const shopThemeColor = colors.find(
        (x) => x.name === "SHOPTHEMECOLORHOVER"
      );

      if (shopThemeColor?.color) {
        document.documentElement.style.setProperty(
          "--shop-theme-color-hover",
          shopThemeColor?.color
        );
      }
    }
  }, [colors]);

  useEffect(() => {
    if (website) {
      document.documentElement.style.setProperty(
        "--shop-border-radius",
        website.shop_b_radius ? website.shop_b_radius : "0px"
      );
    }
  }, [website]);

  const location = useLocation();
  if (
    !window.location.href.includes("/builder/") &&
    location.key === "default" &&
    location.pathname !== "/"
  ) {
    const pageNotFoundData = {
      name: "PageNotFound",
      message: location.pathname,
      stack:
        "Page with pathname " +
        location.pathname +
        " was not found. In ContentRenderer.tsx",
      componentStack: location.pathname,
      href: window.location.href,
    };
    logErrorToBackend(pageNotFoundData);
  }

  // Read cookie

  const renderComponents = () => {
    return webpageComponents?.map((componentId: number) => {
      return (
        <ContentRenderWebpageComponent
          key={componentId}
          componentId={componentId}
        />
      );
    });
  };
  // Empty dependency array to run this effect only once on mount

  return (
    <>
      {fontUrl && (
        <>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link
            rel="preconnect"
            href="https://fonts.gstatic.com"
            crossOrigin="anonymous"
          />
          <link href={fontUrl} rel="stylesheet" />
        </>
      )}
      <div
        className="ContentRenderer"
        style={{ overflow: isBuilder ? "visible" : "hidden" }}
      >
        {renderComponents()}
      </div>
      <CookiePolicyComponent />
      {!isBuilder && <ShoppingCartExpired />}
    </>
  );
}
